import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 order-tab-content p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChangeHistoryDataTable = _resolveComponent("ChangeHistoryDataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "col-12 border-round-3xl mb-0 p-0 pt-2 pl-2 tab-card-content" }, {
      content: _withCtx(() => [
        _createVNode(_component_ChangeHistoryDataTable, { items: _ctx.changesList }, null, 8, ["items"])
      ]),
      _: 1
    })
  ]))
}